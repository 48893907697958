import {
  IFacilityCreate,
  ITenantFacilityParameters,
  IFacilityDTO,
  IFacilityUpdate,
} from "@/models/Facility";
import { IPagination } from "@/models/Pagination";
import { ErrorResponse } from "@/services/axios/error";
import { FacilityAPI } from "@/services/facility";
import store from "@/store";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import { IResponse } from "@/services/axios/entities";

const name = "TenantFacilityModule";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class TenantFacilityModule extends VuexModule {
  private detailTenantFacility = {} as IFacilityDTO;

  private errorCreateFacility = {} as ErrorResponse;
  private errorUpdateFacility = {} as ErrorResponse;
  private errorDetailFacility = {} as ErrorResponse;

  private dataGetListTenantFacility = [] as IFacilityDTO[];
  private errorDataGetListTenantFacility = {} as ErrorResponse;

  private pagination = {} as IPagination;

  private isLoadingListTenantFacility = false;

  get hasCreateErrorMsg() {
    return !isEmpty(this.errorCreateFacility);
  }
  get dataTenantFacilityGetter() {
    return this.dataGetListTenantFacility;
  }
  get detailTenantFacilityGetter() {
    return this.detailTenantFacility;
  }
  get isLoadingListTenantFacilityGetter() {
    return this.isLoadingListTenantFacility;
  }
  get errorDetailFacilityGetter() {
    return this.errorDetailFacility;
  }
  @Mutation
  createFacilitySuccess() {
    ElMessage.success("Create new Facility success.");
    this.errorCreateFacility = {} as ErrorResponse;
  }
  @Mutation
  createFacilityFailure(error: ErrorResponse) {
    this.errorCreateFacility = error;
  }
  @Action
  async createFacility(payload: IFacilityCreate) {
    const servicesFacilityAPI = new FacilityAPI();
    const { error } = await servicesFacilityAPI.createFacility(payload);

    if (error) {
      this.createFacilityFailure(error);
    } else {
      this.createFacilitySuccess();
    }
  }

  @Mutation
  setLoadingListTenantFacility(loading: boolean) {
    this.dataGetListTenantFacility = [] as IFacilityDTO[];
    this.isLoadingListTenantFacility = loading;
  }
  @Mutation
  getListTenantFacilitySuccess(data: any) {
    this.errorDataGetListTenantFacility = {} as ErrorResponse;
    const { Collection, ...rest } = data;
    this.dataGetListTenantFacility = Collection;
    this.pagination = rest;
    this.isLoadingListTenantFacility = false;
  }
  @Mutation
  getListTenantFacilityFailure(error: ErrorResponse) {
    this.errorDataGetListTenantFacility = error;
    this.isLoadingListTenantFacility = false;
  }
  @Action
  async getListTenantFacility(payload: ITenantFacilityParameters) {
    this.setLoadingListTenantFacility(true);
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getListFacility(payload);

    if (error) {
      this.getListTenantFacilityFailure(error);
    } else {
      this.getListTenantFacilitySuccess(data);
    }
  }

  @Mutation
  updateFacilitySuccess(data: any) {
    ElMessage.success("Updated Facility success.");
    this.errorUpdateFacility = {} as ErrorResponse;
  }
  @Mutation
  updateFacilityFailure(error: ErrorResponse) {
    this.errorUpdateFacility = error;
  }
  @Action
  async updateFacility(payload: IFacilityUpdate) {
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.updateFacility(
      payload.FacilityId,
      payload
    );

    if (error) {
      this.updateFacilityFailure(error);
    } else {
      this.updateFacilitySuccess(data);
    }
  }

  @Mutation
  getTenantFacilityDetailSuccess(data: any) {
    this.errorDetailFacility = {} as ErrorResponse;
    this.detailTenantFacility = data;
  }
  @Mutation
  getTenantFacilityDetailFailure(error: ErrorResponse) {
    this.errorDetailFacility = error;
  }
  @Action
  async getFacility(payload: { FacilityId: string }): Promise<IResponse> {
    const { FacilityId } = payload;
    const servicesFacilityAPI = new FacilityAPI();
    const { data, error } = await servicesFacilityAPI.getFacility(FacilityId);

    if (error) {
      this.getTenantFacilityDetailFailure(error);
      return { error };
    } else {
      this.getTenantFacilityDetailSuccess(data);
      return { data };
    }
  }
  @Mutation
  resetDetailTenantFacility() {
    this.detailTenantFacility = {} as IFacilityDTO;
  }
}
export default getModule(TenantFacilityModule);
