import {
  IFacilityDTO,
  IFacilityUpdate,
  ITenantFacilityParameters,
} from "@/models/Facility";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const FACILITY = "Facilities";

export class FacilityAPI extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_DOMAIN_API_SERVER);
  }
  public createFacility = async (data: IFacilityDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${FACILITY}`, data)
      .catch(catchAxiosError);
    return response;
  };

  public getListFacility = async (
    params: ITenantFacilityParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}`, { params })
      .catch(catchAxiosError);
    return response;
  };

  public updateFacility = async (
    FacilityId: string,
    data: IFacilityUpdate
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${FACILITY}/${FacilityId}`, data)
      .catch(catchAxiosError);
    return response;
  };

  public getFacility = async (FacilityId: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${FACILITY}/${FacilityId}`)
      .catch(catchAxiosError);
    return response;
  };
}
